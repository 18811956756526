// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("channels")
require('./argon')
require('./js-cookie')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import 'bootstrap/dist/js/bootstrap';
import 'bootstrap';
import 'bootstrap-slider/dist/bootstrap-slider';
import 'intl-tel-input/build/js/data';
import 'intl-tel-input/build/js/intlTelInput-jquery';
import 'intl-tel-input/build/js/utils';
import 'select2';
import '../stylesheets/application';

require.context('../images', true)

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $(".select-parent").select2();
    $(".select-host").select2({
      allowClear: true
    });
})

$(function () {
  setTimeout(function() {
    $('.animated.fadeInDown.alert-info').addClass('fadeOut');
  }, 5000);
});
// document.addEventListener("turbolinks:load", () => {
//     $("#space_controller_attributes_range").slider({});
//     $("#space_controller_attributes_range").on("slide", function (slideEvt) {
//         $("#ex6SliderVal").text(slideEvt.value);
//     });
// })
